import moment from "moment/src/moment";
const actions = {
  convertDate(date, locale = undefined, format = undefined) {
    if (locale !== undefined) {
      moment.locale(locale);
    }
    var value = "";
    if (format === undefined) {
      value = this.utc_To_YYYYMMDD_HHmmss(date);
    } else {
      format = format.replace("yyyy", "YYYY").replace("dd", "DD");
      value = moment
        .utc(date)
        .local()
        .format(format);
    }

    if (value === "Invalid date") {
      return "";
    }
    return value;
  },
  // 將formio的小寫轉成mement的大寫
  formioToMomentFormat(format) {
    var f = format.replace("yyyy", "YYYY").replace("dd", "DD");

    f = f.replace("hh", "HH").replace("a", "");
    return f;

    // var newDayFormat = "";
    // if (f.includes("YYYY")) {
    //   newDayFormat += "YYYY";
    // }
    // if (f.includes("MM")) {
    //   newDayFormat += "/MM";
    // }
    // if (f.includes("DD")) {
    //   newDayFormat += "/DD";
    // }

    // var newTimeFormat = "";
    // if (f.includes("hh")) {
    //   newTimeFormat += "HH";
    // }
    // if (f.includes("mm")) {
    //   newTimeFormat += ":mm";
    // }
    // if (f.includes("ss")) {
    //   newTimeFormat += ":ss";
    // }

    // if (newTimeFormat === "") {
    //   return newDayFormat;
    // } else if (newDayFormat === "") {
    //   return newTimeFormat;
    // } else {
    //   return newDayFormat + " " + newTimeFormat;
    // }
  },
  dateForVuetify(a, endate, entime) {
    var date = new Date(a);

    if (date !== "" || date !== undefined) {
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      var time = hours + ":" + minutes + ":" + seconds;
      day = year + "-" + month + "-" + day;
      if (endate && entime) {
        return day + " " + time;
      } else if (endate === true && entime === false) {
        return day;
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
  // 將vuetify calender的date string 轉為formio格式
  vuetifyDateToFormio(date) {
    var a = moment(date).format();

    return a;
  },

  // timestamp to date
  timestampToDate(data) {
    var datetime = new Date();
    datetime.setTime(data);
    // var str = datetime.toDateString()
    var a = moment(data).format();
    return a;
  },

  /**  UTC時間轉YYYY/MM/DD HH:mm:ss*/
  utc_To_YYYYMMDD_HHmmss(data) {
    return moment
      .utc(data)
      .local()
      .format("YYYY/MM/DD HH:mm:ss");
  },
};

export default actions;
