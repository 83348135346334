<template>
  <div>
    <input type="hidden" :value="value" v-on:input="setValue" />
    <v-dialog v-model="value" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading....
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setValue(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles.scss";
</style>
